<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">营销赋能·方案详情</div>
      <div class="sub-title">营销赋能增长策略</div>
      <div class="large-paragraph-describe">
        全球蛙从品牌实际需求出发，量身定制营销方案，打造全域全渠道全场景营销体系，通过支付补贴、百万爆品、导购分销、品牌派券、定制营销“1+4”的战略规划，推出支付券、品牌券、主题营销、爆品打造、导购大赛、新品推广等综合营销模式，匹配零售侧、平台侧、支付侧多方渠道和资源，并全程进行数据赋能，以迅速倍增的销售业绩及远高行业数倍的派券核销率，助力品牌商迅速提升市场占有率，实现品牌生态收益倍增。
      </div>
    </div>
    <img
      class="large-image-h568"
      src="@/image/brand/img_market_brand-big@3x.png"
    />
    <div class="func-module-container">
      <div class="func-title">技术赋能·应用案例</div>
      <div class="func-sub-title">深耕私域新营销，助力品牌新增长</div>
    </div>
    <div class="large-image-container">
      <div class="large-image-h280">
        <img src="@/image/brand/img_ha_operate_brand@3x.png" />
      </div>
      <div class="large-image-h280">
        <img src="@/image/brand/img_bj_operate_brand@3x.png" />
      </div>
      <div class="large-image-h280">
        <img src="@/image/brand/img_ryytn_operate_brand@3x.png" />
      </div>
    </div>
    <action-sheet module="新品牌营销赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    bigImages: [
      require("@/image/retail/img_explosives_market@3x.png"),
      require("@/image/retail/img_guide_market@3x.png"),
      require("@/image/retail/img_brand_market@3x.png"),
      require("@/image/retail/img_marketing_market@3x.png"),
    ],
    operatePowerBigImages: [
      require("@/image/retail/img_mth_operate@3x.png"),
      require("@/image/retail/img_csf_operate@3x.png"),
      require("@/image/retail/img_mdj_operate@3x.png"),
    ],
    brandPowerImages: [
      require("@/image/retail/img_bj_market@3x.png"),
      require("@/image/retail/img_sc_market@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-paragraph-describe {
  margin-bottom: 0 !important;
}
.large-image-h568 {
  margin: 50px 0;
}
.large-image-container {
  padding: 0 !important;
  padding-bottom: 60px !important;
}
.large-image-h280 {
  margin-bottom: 40px !important;
}
.func-module-container {
  padding-bottom: 40px !important;
}
</style>
